// Generated from DSLLexer.g4 by ANTLR 4.9.2
// jshint ignore: start
import antlr4 from 'antlr4';



const serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786",
    "\u5964\u0002\u001b\u0139\b\u0001\b\u0001\b\u0001\b\u0001\u0004\u0002",
    "\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t\u0004\u0004\u0005\t\u0005",
    "\u0004\u0006\t\u0006\u0004\u0007\t\u0007\u0004\b\t\b\u0004\t\t\t\u0004",
    "\n\t\n\u0004\u000b\t\u000b\u0004\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e",
    "\u0004\u000f\t\u000f\u0004\u0010\t\u0010\u0004\u0011\t\u0011\u0004\u0012",
    "\t\u0012\u0004\u0013\t\u0013\u0004\u0014\t\u0014\u0004\u0015\t\u0015",
    "\u0004\u0016\t\u0016\u0004\u0017\t\u0017\u0004\u0018\t\u0018\u0004\u0019",
    "\t\u0019\u0004\u001a\t\u001a\u0003\u0002\u0003\u0002\u0003\u0002\u0003",
    "\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0006\u0002@\n\u0002\r\u0002",
    "\u000e\u0002A\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003",
    "\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0005",
    "\u0004T\n\u0004\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0006",
    "\u0006l\n\u0006\r\u0006\u000e\u0006m\u0003\u0006\u0003\u0006\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\b\u0003\b\u0003\b\u0003\b\u0003\b\u0003",
    "\b\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003",
    "\t\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0006\n\u0096\n\n",
    "\r\n\u000e\n\u0097\u0003\n\u0003\n\u0003\u000b\u0003\u000b\u0003\u000b",
    "\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b",
    "\u0003\u000b\u0007\u000b\u00a6\n\u000b\f\u000b\u000e\u000b\u00a9\u000b",
    "\u000b\u0003\u000b\u0003\u000b\u0003\f\u0003\f\u0003\f\u0003\f\u0003",
    "\f\u0003\f\u0003\f\u0003\f\u0003\f\u0007\f\u00b6\n\f\f\f\u000e\f\u00b9",
    "\u000b\f\u0003\r\u0003\r\u0003\r\u0003\r\u0003\r\u0003\r\u0003\r\u0003",
    "\r\u0003\r\u0003\r\u0003\r\u0007\r\u00c6\n\r\f\r\u000e\r\u00c9\u000b",
    "\r\u0003\r\u0003\r\u0003\r\u0003\r\u0003\u000e\u0003\u000e\u0003\u000e",
    "\u0003\u000e\u0003\u000e\u0003\u000e\u0003\u000e\u0003\u000e\u0003\u000e",
    "\u0007\u000e\u00d8\n\u000e\f\u000e\u000e\u000e\u00db\u000b\u000e\u0003",
    "\u000f\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u000f\u0003",
    "\u000f\u0003\u000f\u0003\u000f\u0007\u000f\u00e6\n\u000f\f\u000f\u000e",
    "\u000f\u00e9\u000b\u000f\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010",
    "\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010",
    "\u0003\u0010\u0003\u0010\u0003\u0010\u0007\u0010\u00f8\n\u0010\f\u0010",
    "\u000e\u0010\u00fb\u000b\u0010\u0003\u0010\u0003\u0010\u0007\u0010\u00ff",
    "\n\u0010\f\u0010\u000e\u0010\u0102\u000b\u0010\u0003\u0010\u0003\u0010",
    "\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0012\u0006\u0012",
    "\u010b\n\u0012\r\u0012\u000e\u0012\u010c\u0003\u0013\u0006\u0013\u0110",
    "\n\u0013\r\u0013\u000e\u0013\u0111\u0003\u0013\u0003\u0013\u0003\u0014",
    "\u0003\u0014\u0003\u0014\u0003\u0014\u0003\u0015\u0006\u0015\u011b\n",
    "\u0015\r\u0015\u000e\u0015\u011c\u0003\u0016\u0006\u0016\u0120\n\u0016",
    "\r\u0016\u000e\u0016\u0121\u0003\u0017\u0003\u0017\u0003\u0017\u0003",
    "\u0017\u0003\u0018\u0003\u0018\u0007\u0018\u012a\n\u0018\f\u0018\u000e",
    "\u0018\u012d\u000b\u0018\u0003\u0018\u0003\u0018\u0003\u0019\u0006\u0019",
    "\u0132\n\u0019\r\u0019\u000e\u0019\u0133\u0003\u001a\u0003\u001a\u0003",
    "\u001a\u0003\u001a\u0002\u0002\u001b\u0006\u0003\b\u0004\n\u0005\f\u0006",
    "\u000e\u0007\u0010\b\u0012\t\u0014\n\u0016\u000b\u0018\f\u001a\r\u001c",
    "\u000e\u001e\u000f \u0010\"\u0011$\u0012&\u0013(\u0014*\u0015,\u0016",
    ".\u00170\u00182\u00194\u001a6\u001b\u0006\u0002\u0003\u0004\u0005\u0007",
    "\u0005\u0002\u000b\f\u000f\u000f\"\"\u0003\u00022;\u0007\u0002\f\f\u000f",
    "\u000f\"\"]]__\t\u0002\f\f\u000f\u000f\"\",,..]]__\u0006\u0002$$))]",
    "]__\u0002\u0146\u0002\u0006\u0003\u0002\u0002\u0002\u0002\b\u0003\u0002",
    "\u0002\u0002\u0002\n\u0003\u0002\u0002\u0002\u0002\f\u0003\u0002\u0002",
    "\u0002\u0002\u000e\u0003\u0002\u0002\u0002\u0002\u0010\u0003\u0002\u0002",
    "\u0002\u0002\u0012\u0003\u0002\u0002\u0002\u0002\u0014\u0003\u0002\u0002",
    "\u0002\u0002\u0016\u0003\u0002\u0002\u0002\u0002\u0018\u0003\u0002\u0002",
    "\u0002\u0002\u001a\u0003\u0002\u0002\u0002\u0002\u001c\u0003\u0002\u0002",
    "\u0002\u0002\u001e\u0003\u0002\u0002\u0002\u0002 \u0003\u0002\u0002",
    "\u0002\u0002\"\u0003\u0002\u0002\u0002\u0002$\u0003\u0002\u0002\u0002",
    "\u0002&\u0003\u0002\u0002\u0002\u0003(\u0003\u0002\u0002\u0002\u0004",
    "*\u0003\u0002\u0002\u0002\u0004,\u0003\u0002\u0002\u0002\u0004.\u0003",
    "\u0002\u0002\u0002\u00040\u0003\u0002\u0002\u0002\u00042\u0003\u0002",
    "\u0002\u0002\u00054\u0003\u0002\u0002\u0002\u00056\u0003\u0002\u0002",
    "\u0002\u00068\u0003\u0002\u0002\u0002\bE\u0003\u0002\u0002\u0002\nS",
    "\u0003\u0002\u0002\u0002\fU\u0003\u0002\u0002\u0002\u000e^\u0003\u0002",
    "\u0002\u0002\u0010q\u0003\u0002\u0002\u0002\u0012\u0080\u0003\u0002",
    "\u0002\u0002\u0014\u0086\u0003\u0002\u0002\u0002\u0016\u008f\u0003\u0002",
    "\u0002\u0002\u0018\u009b\u0003\u0002\u0002\u0002\u001a\u00ac\u0003\u0002",
    "\u0002\u0002\u001c\u00ba\u0003\u0002\u0002\u0002\u001e\u00ce\u0003\u0002",
    "\u0002\u0002 \u00dc\u0003\u0002\u0002\u0002\"\u00ea\u0003\u0002\u0002",
    "\u0002$\u0105\u0003\u0002\u0002\u0002&\u010a\u0003\u0002\u0002\u0002",
    "(\u010f\u0003\u0002\u0002\u0002*\u0115\u0003\u0002\u0002\u0002,\u011a",
    "\u0003\u0002\u0002\u0002.\u011f\u0003\u0002\u0002\u00020\u0123\u0003",
    "\u0002\u0002\u00022\u0127\u0003\u0002\u0002\u00024\u0131\u0003\u0002",
    "\u0002\u00026\u0135\u0003\u0002\u0002\u000289\u0007U\u0002\u00029:\u0007",
    "q\u0002\u0002:;\u0007w\u0002\u0002;<\u0007p\u0002\u0002<=\u0007f\u0002",
    "\u0002=?\u0003\u0002\u0002\u0002>@\u0005$\u0011\u0002?>\u0003\u0002",
    "\u0002\u0002@A\u0003\u0002\u0002\u0002A?\u0003\u0002\u0002\u0002AB\u0003",
    "\u0002\u0002\u0002BC\u0003\u0002\u0002\u0002CD\b\u0002\u0002\u0002D",
    "\u0007\u0003\u0002\u0002\u0002EF\u0007c\u0002\u0002FG\u0007u\u0002\u0002",
    "GH\u0007\"\u0002\u0002H\t\u0003\u0002\u0002\u0002IJ\u0007R\u0002\u0002",
    "JK\u0007t\u0002\u0002KL\u0007g\u0002\u0002LM\u0007u\u0002\u0002MN\u0007",
    "g\u0002\u0002NT\u0007v\u0002\u0002OP\u0007E\u0002\u0002PQ\u0007n\u0002",
    "\u0002QR\u0007k\u0002\u0002RT\u0007r\u0002\u0002SI\u0003\u0002\u0002",
    "\u0002SO\u0003\u0002\u0002\u0002T\u000b\u0003\u0002\u0002\u0002UV\u0007",
    "G\u0002\u0002VW\u0007p\u0002\u0002WX\u0007f\u0002\u0002XY\u0007U\u0002",
    "\u0002YZ\u0007q\u0002\u0002Z[\u0007w\u0002\u0002[\\\u0007p\u0002\u0002",
    "\\]\u0007f\u0002\u0002]\r\u0003\u0002\u0002\u0002^_\u0007E\u0002\u0002",
    "_`\u0007q\u0002\u0002`a\u0007o\u0002\u0002ab\u0007d\u0002\u0002bc\u0007",
    "k\u0002\u0002cd\u0007p\u0002\u0002de\u0007c\u0002\u0002ef\u0007v\u0002",
    "\u0002fg\u0007k\u0002\u0002gh\u0007q\u0002\u0002hi\u0007p\u0002\u0002",
    "ik\u0003\u0002\u0002\u0002jl\u0005$\u0011\u0002kj\u0003\u0002\u0002",
    "\u0002lm\u0003\u0002\u0002\u0002mk\u0003\u0002\u0002\u0002mn\u0003\u0002",
    "\u0002\u0002no\u0003\u0002\u0002\u0002op\b\u0006\u0002\u0002p\u000f",
    "\u0003\u0002\u0002\u0002qr\u0007G\u0002\u0002rs\u0007p\u0002\u0002s",
    "t\u0007f\u0002\u0002tu\u0007E\u0002\u0002uv\u0007q\u0002\u0002vw\u0007",
    "o\u0002\u0002wx\u0007d\u0002\u0002xy\u0007k\u0002\u0002yz\u0007p\u0002",
    "\u0002z{\u0007c\u0002\u0002{|\u0007v\u0002\u0002|}\u0007k\u0002\u0002",
    "}~\u0007q\u0002\u0002~\u007f\u0007p\u0002\u0002\u007f\u0011\u0003\u0002",
    "\u0002\u0002\u0080\u0081\u0007V\u0002\u0002\u0081\u0082\u0007t\u0002",
    "\u0002\u0082\u0083\u0007c\u0002\u0002\u0083\u0084\u0007e\u0002\u0002",
    "\u0084\u0085\u0007m\u0002\u0002\u0085\u0013\u0003\u0002\u0002\u0002",
    "\u0086\u0087\u0007G\u0002\u0002\u0087\u0088\u0007p\u0002\u0002\u0088",
    "\u0089\u0007f\u0002\u0002\u0089\u008a\u0007V\u0002\u0002\u008a\u008b",
    "\u0007t\u0002\u0002\u008b\u008c\u0007c\u0002\u0002\u008c\u008d\u0007",
    "e\u0002\u0002\u008d\u008e\u0007m\u0002\u0002\u008e\u0015\u0003\u0002",
    "\u0002\u0002\u008f\u0090\u0007R\u0002\u0002\u0090\u0091\u0007n\u0002",
    "\u0002\u0091\u0092\u0007c\u0002\u0002\u0092\u0093\u0007{\u0002\u0002",
    "\u0093\u0095\u0003\u0002\u0002\u0002\u0094\u0096\u0005$\u0011\u0002",
    "\u0095\u0094\u0003\u0002\u0002\u0002\u0096\u0097\u0003\u0002\u0002\u0002",
    "\u0097\u0095\u0003\u0002\u0002\u0002\u0097\u0098\u0003\u0002\u0002\u0002",
    "\u0098\u0099\u0003\u0002\u0002\u0002\u0099\u009a\b\n\u0002\u0002\u009a",
    "\u0017\u0003\u0002\u0002\u0002\u009b\u009c\u0007R\u0002\u0002\u009c",
    "\u009d\u0007c\u0002\u0002\u009d\u009e\u0007v\u0002\u0002\u009e\u009f",
    "\u0007v\u0002\u0002\u009f\u00a0\u0007g\u0002\u0002\u00a0\u00a1\u0007",
    "t\u0002\u0002\u00a1\u00a2\u0007p\u0002\u0002\u00a2\u00a3\u0007<\u0002",
    "\u0002\u00a3\u00a7\u0003\u0002\u0002\u0002\u00a4\u00a6\u0005$\u0011",
    "\u0002\u00a5\u00a4\u0003\u0002\u0002\u0002\u00a6\u00a9\u0003\u0002\u0002",
    "\u0002\u00a7\u00a5\u0003\u0002\u0002\u0002\u00a7\u00a8\u0003\u0002\u0002",
    "\u0002\u00a8\u00aa\u0003\u0002\u0002\u0002\u00a9\u00a7\u0003\u0002\u0002",
    "\u0002\u00aa\u00ab\b\u000b\u0002\u0002\u00ab\u0019\u0003\u0002\u0002",
    "\u0002\u00ac\u00ad\u0007T\u0002\u0002\u00ad\u00ae\u0007g\u0002\u0002",
    "\u00ae\u00af\u0007r\u0002\u0002\u00af\u00b0\u0007g\u0002\u0002\u00b0",
    "\u00b1\u0007c\u0002\u0002\u00b1\u00b2\u0007v\u0002\u0002\u00b2\u00b3",
    "\u0007<\u0002\u0002\u00b3\u00b7\u0003\u0002\u0002\u0002\u00b4\u00b6",
    "\u0005$\u0011\u0002\u00b5\u00b4\u0003\u0002\u0002\u0002\u00b6\u00b9",
    "\u0003\u0002\u0002\u0002\u00b7\u00b5\u0003\u0002\u0002\u0002\u00b7\u00b8",
    "\u0003\u0002\u0002\u0002\u00b8\u001b\u0003\u0002\u0002\u0002\u00b9\u00b7",
    "\u0003\u0002\u0002\u0002\u00ba\u00bb\u0007W\u0002\u0002\u00bb\u00bc",
    "\u0007u\u0002\u0002\u00bc\u00bd\u0007g\u0002\u0002\u00bd\u00be\u0007",
    "U\u0002\u0002\u00be\u00bf\u0007q\u0002\u0002\u00bf\u00c0\u0007w\u0002",
    "\u0002\u00c0\u00c1\u0007p\u0002\u0002\u00c1\u00c2\u0007f\u0002\u0002",
    "\u00c2\u00c3\u0007<\u0002\u0002\u00c3\u00c7\u0003\u0002\u0002\u0002",
    "\u00c4\u00c6\u0005$\u0011\u0002\u00c5\u00c4\u0003\u0002\u0002\u0002",
    "\u00c6\u00c9\u0003\u0002\u0002\u0002\u00c7\u00c5\u0003\u0002\u0002\u0002",
    "\u00c7\u00c8\u0003\u0002\u0002\u0002\u00c8\u00ca\u0003\u0002\u0002\u0002",
    "\u00c9\u00c7\u0003\u0002\u0002\u0002\u00ca\u00cb\u0007$\u0002\u0002",
    "\u00cb\u00cc\u0003\u0002\u0002\u0002\u00cc\u00cd\b\r\u0003\u0002\u00cd",
    "\u001d\u0003\u0002\u0002\u0002\u00ce\u00cf\u0007X\u0002\u0002\u00cf",
    "\u00d0\u0007q\u0002\u0002\u00d0\u00d1\u0007n\u0002\u0002\u00d1\u00d2",
    "\u0007w\u0002\u0002\u00d2\u00d3\u0007o\u0002\u0002\u00d3\u00d4\u0007",
    "g\u0002\u0002\u00d4\u00d5\u0007<\u0002\u0002\u00d5\u00d9\u0003\u0002",
    "\u0002\u0002\u00d6\u00d8\u0005$\u0011\u0002\u00d7\u00d6\u0003\u0002",
    "\u0002\u0002\u00d8\u00db\u0003\u0002\u0002\u0002\u00d9\u00d7\u0003\u0002",
    "\u0002\u0002\u00d9\u00da\u0003\u0002\u0002\u0002\u00da\u001f\u0003\u0002",
    "\u0002\u0002\u00db\u00d9\u0003\u0002\u0002\u0002\u00dc\u00dd\u0007Q",
    "\u0002\u0002\u00dd\u00de\u0007h\u0002\u0002\u00de\u00df\u0007h\u0002",
    "\u0002\u00df\u00e0\u0007u\u0002\u0002\u00e0\u00e1\u0007g\u0002\u0002",
    "\u00e1\u00e2\u0007v\u0002\u0002\u00e2\u00e3\u0007<\u0002\u0002\u00e3",
    "\u00e7\u0003\u0002\u0002\u0002\u00e4\u00e6\u0005$\u0011\u0002\u00e5",
    "\u00e4\u0003\u0002\u0002\u0002\u00e6\u00e9\u0003\u0002\u0002\u0002\u00e7",
    "\u00e5\u0003\u0002\u0002\u0002\u00e7\u00e8\u0003\u0002\u0002\u0002\u00e8",
    "!\u0003\u0002\u0002\u0002\u00e9\u00e7\u0003\u0002\u0002\u0002\u00ea",
    "\u00eb\u0007E\u0002\u0002\u00eb\u00ec\u0007q\u0002\u0002\u00ec\u00ed",
    "\u0007o\u0002\u0002\u00ed\u00ee\u0007r\u0002\u0002\u00ee\u00ef\u0007",
    "q\u0002\u0002\u00ef\u00f0\u0007p\u0002\u0002\u00f0\u00f1\u0007g\u0002",
    "\u0002\u00f1\u00f2\u0007p\u0002\u0002\u00f2\u00f3\u0007v\u0002\u0002",
    "\u00f3\u00f4\u0007u\u0002\u0002\u00f4\u00f5\u0007<\u0002\u0002\u00f5",
    "\u00f9\u0003\u0002\u0002\u0002\u00f6\u00f8\u0005$\u0011\u0002\u00f7",
    "\u00f6\u0003\u0002\u0002\u0002\u00f8\u00fb\u0003\u0002\u0002\u0002\u00f9",
    "\u00f7\u0003\u0002\u0002\u0002\u00f9\u00fa\u0003\u0002\u0002\u0002\u00fa",
    "\u00fc\u0003\u0002\u0002\u0002\u00fb\u00f9\u0003\u0002\u0002\u0002\u00fc",
    "\u0100\u0007]\u0002\u0002\u00fd\u00ff\u0005$\u0011\u0002\u00fe\u00fd",
    "\u0003\u0002\u0002\u0002\u00ff\u0102\u0003\u0002\u0002\u0002\u0100\u00fe",
    "\u0003\u0002\u0002\u0002\u0100\u0101\u0003\u0002\u0002\u0002\u0101\u0103",
    "\u0003\u0002\u0002\u0002\u0102\u0100\u0003\u0002\u0002\u0002\u0103\u0104",
    "\b\u0010\u0004\u0002\u0104#\u0003\u0002\u0002\u0002\u0105\u0106\t\u0002",
    "\u0002\u0002\u0106\u0107\u0003\u0002\u0002\u0002\u0107\u0108\b\u0011",
    "\u0005\u0002\u0108%\u0003\u0002\u0002\u0002\u0109\u010b\t\u0003\u0002",
    "\u0002\u010a\u0109\u0003\u0002\u0002\u0002\u010b\u010c\u0003\u0002\u0002",
    "\u0002\u010c\u010a\u0003\u0002\u0002\u0002\u010c\u010d\u0003\u0002\u0002",
    "\u0002\u010d\'\u0003\u0002\u0002\u0002\u010e\u0110\n\u0004\u0002\u0002",
    "\u010f\u010e\u0003\u0002\u0002\u0002\u0110\u0111\u0003\u0002\u0002\u0002",
    "\u0111\u010f\u0003\u0002\u0002\u0002\u0111\u0112\u0003\u0002\u0002\u0002",
    "\u0112\u0113\u0003\u0002\u0002\u0002\u0113\u0114\b\u0013\u0006\u0002",
    "\u0114)\u0003\u0002\u0002\u0002\u0115\u0116\u0007,\u0002\u0002\u0116",
    "\u0117\u0003\u0002\u0002\u0002\u0117\u0118\b\u0014\u0005\u0002\u0118",
    "+\u0003\u0002\u0002\u0002\u0119\u011b\t\u0003\u0002\u0002\u011a\u0119",
    "\u0003\u0002\u0002\u0002\u011b\u011c\u0003\u0002\u0002\u0002\u011c\u011a",
    "\u0003\u0002\u0002\u0002\u011c\u011d\u0003\u0002\u0002\u0002\u011d-",
    "\u0003\u0002\u0002\u0002\u011e\u0120\n\u0005\u0002\u0002\u011f\u011e",
    "\u0003\u0002\u0002\u0002\u0120\u0121\u0003\u0002\u0002\u0002\u0121\u011f",
    "\u0003\u0002\u0002\u0002\u0121\u0122\u0003\u0002\u0002\u0002\u0122/",
    "\u0003\u0002\u0002\u0002\u0123\u0124\u0007_\u0002\u0002\u0124\u0125",
    "\u0003\u0002\u0002\u0002\u0125\u0126\b\u0017\u0006\u0002\u01261\u0003",
    "\u0002\u0002\u0002\u0127\u012b\u0007.\u0002\u0002\u0128\u012a\u0005",
    "$\u0011\u0002\u0129\u0128\u0003\u0002\u0002\u0002\u012a\u012d\u0003",
    "\u0002\u0002\u0002\u012b\u0129\u0003\u0002\u0002\u0002\u012b\u012c\u0003",
    "\u0002\u0002\u0002\u012c\u012e\u0003\u0002\u0002\u0002\u012d\u012b\u0003",
    "\u0002\u0002\u0002\u012e\u012f\b\u0018\u0005\u0002\u012f3\u0003\u0002",
    "\u0002\u0002\u0130\u0132\n\u0006\u0002\u0002\u0131\u0130\u0003\u0002",
    "\u0002\u0002\u0132\u0133\u0003\u0002\u0002\u0002\u0133\u0131\u0003\u0002",
    "\u0002\u0002\u0133\u0134\u0003\u0002\u0002\u0002\u01345\u0003\u0002",
    "\u0002\u0002\u0135\u0136\u0007$\u0002\u0002\u0136\u0137\u0003\u0002",
    "\u0002\u0002\u0137\u0138\b\u001a\u0006\u0002\u01387\u0003\u0002\u0002",
    "\u0002\u0017\u0002\u0003\u0004\u0005ASm\u0097\u00a7\u00b7\u00c7\u00d9",
    "\u00e7\u00f9\u0100\u010c\u0111\u011c\u0121\u012b\u0133\u0007\u0004\u0003",
    "\u0002\u0004\u0005\u0002\u0004\u0004\u0002\u0002\u0003\u0002\u0004\u0002",
    "\u0002"].join("");


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class DSLLexer extends antlr4.Lexer {

    static grammarFileName = "DSLLexer.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE", "TEXT_MODE", "COMPONENT_MODE", "USESOUND_MODE" ];
	static literalNames = [ null, null, "'as '", null, "'EndSound'", null, 
                         "'EndCombination'", "'Track'", "'EndTrack'", null, 
                         null, null, null, null, null, null, null, null, 
                         null, "'*'", null, null, "']'", null, null, "'\"'" ];
	static symbolicNames = [ null, "SOUND_START", "AS", "SUBTYPE", "SOUND_END", 
                          "COMBINATION_START", "COMBINATION_END", "TRACK_START", 
                          "TRACK_END", "PLAY_START", "PATTERN_START", "REPEAT_START", 
                          "USESOUND_START", "VOLUME_START", "OFFSET_START", 
                          "COMPONENTS_START", "WS", "NUM", "TEXT", "MULTIPLY", 
                          "COMPONENT_REPEAT", "COMPONENT_NAME", "COMPONENTS_END", 
                          "COMMA", "USESOUNDS", "USESOUND_END" ];
	static ruleNames = [ "SOUND_START", "AS", "SUBTYPE", "SOUND_END", "COMBINATION_START", 
                      "COMBINATION_END", "TRACK_START", "TRACK_END", "PLAY_START", 
                      "PATTERN_START", "REPEAT_START", "USESOUND_START", 
                      "VOLUME_START", "OFFSET_START", "COMPONENTS_START", 
                      "WS", "NUM", "TEXT", "MULTIPLY", "COMPONENT_REPEAT", 
                      "COMPONENT_NAME", "COMPONENTS_END", "COMMA", "USESOUNDS", 
                      "USESOUND_END" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    }

    get atn() {
        return atn;
    }
}

DSLLexer.EOF = antlr4.Token.EOF;
DSLLexer.SOUND_START = 1;
DSLLexer.AS = 2;
DSLLexer.SUBTYPE = 3;
DSLLexer.SOUND_END = 4;
DSLLexer.COMBINATION_START = 5;
DSLLexer.COMBINATION_END = 6;
DSLLexer.TRACK_START = 7;
DSLLexer.TRACK_END = 8;
DSLLexer.PLAY_START = 9;
DSLLexer.PATTERN_START = 10;
DSLLexer.REPEAT_START = 11;
DSLLexer.USESOUND_START = 12;
DSLLexer.VOLUME_START = 13;
DSLLexer.OFFSET_START = 14;
DSLLexer.COMPONENTS_START = 15;
DSLLexer.WS = 16;
DSLLexer.NUM = 17;
DSLLexer.TEXT = 18;
DSLLexer.MULTIPLY = 19;
DSLLexer.COMPONENT_REPEAT = 20;
DSLLexer.COMPONENT_NAME = 21;
DSLLexer.COMPONENTS_END = 22;
DSLLexer.COMMA = 23;
DSLLexer.USESOUNDS = 24;
DSLLexer.USESOUND_END = 25;

DSLLexer.TEXT_MODE = 1;
DSLLexer.COMPONENT_MODE = 2;
DSLLexer.USESOUND_MODE = 3;




